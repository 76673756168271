import React from "react";
import { ApolloProvider } from "react-apollo";
import { client } from "./src/apollo";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./src/state/index.js";
import "./src/styles/global.scss";

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const array = location.pathname.split("/");
  if (array.length >= 6 && array[2] === "eshop") {
    window.scrollTo(0, 100);
    return false;
  }
};
export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </PersistGate>
    </Provider>
  );
};
