const initialState = [
  /**
   *
   * {
   * fr:{
   * title,
   * path
   * },
   * en:{
   * title,
   * path
   * }
   * img
   * color: string,
   * price: number,
   * size: number,
   * sku: number,
   * quantity: number
   *
   * }
   */
];

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PRODUCT": {
      return state.concat({
        en: {
          title: action.payload.en.title,
          path: action.payload.en.path
        },
        fr: {
          title: action.payload.fr.title,
          path: action.payload.fr.path
        },
        color: action.payload.color,
        img: action.payload.img,
        price: action.payload.price,
        size: action.payload.size,
        sku: action.payload.sku,
        shopifyId: action.payload.shopifyId,
        quantity: action.payload.quantity
      });
    }
    case "UPDATE_BASKET_QUANTITY": {
      return state.map(product => {
        if (product.sku === action.payload.sku) {
          return {
            ...product,
            quantity: action.payload.quantity
          };
        }
        return product;
      });
    }

    case "REMOVE_PRODUCT_FROM_BASKET": {
      return state.filter(product => {
        return product.sku !== action.payload.sku;
      });
    }
    default:
      return state;
  }
};

export default productReducer;
