const initialState = {
  isLoggedIn: false,
  customerInfo: {},
  hasClosedPopup: false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PROFILE": {
      return { ...state, isLoggedIn: true, customerInfo: action.payload.token };
    }
    case "CLEAR_PROFILE": {
      return initialState;
    }
    case "HAS_CLOSED_POPUP": {
      return { ...state, hasClosedPopup: action.payload.date };
    }
    default:
      return state;
  }
};

export default profileReducer;
