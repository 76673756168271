import  ApolloClient  from 'apollo-client';
import  {createHttpLink}  from 'apollo-link-http';
import  {setContext}  from 'apollo-link-context';
import  {InMemoryCache}  from 'apollo-cache-inmemory';


export const isBrowser = typeof window !== "undefined";
const httpLink = createHttpLink({ uri: process.env.GATSBY_SHOPIFY_GRAPHQL_URL })

const middlewareLink = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    'Content-Type': 'application/graphql',
  }
}))

export const client = isBrowser ? new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
  
}) : {};