import { createStore, combineReducers } from "redux";
import productReducer from "./productReducer";
import profileReducer from "./profileReducer";
import headerReducer from "./headerReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
}

const reducer = combineReducers({
    productReducer, profileReducer, headerReducer
});

const pReducer = persistReducer(persistConfig, reducer);

export const store = createStore(pReducer);
export const persistor = persistStore(store);
