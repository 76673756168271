const initialState = {
    section: 0,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SWITCH_SECTION": {
            return { ...state, section: action.payload.section };
        }
        default:
            return state;
    }
};

export default profileReducer;
