exports.components = {
  "component---src-components-collections-index-dynamic-jsx": () => import("./../../../src/components/collections/indexDynamic.jsx" /* webpackChunkName: "component---src-components-collections-index-dynamic-jsx" */),
  "component---src-components-product-index-jsx": () => import("./../../../src/components/product/index.jsx" /* webpackChunkName: "component---src-components-product-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-checkout-index-jsx": () => import("./../../../src/pages/checkout/index.jsx" /* webpackChunkName: "component---src-pages-checkout-index-jsx" */),
  "component---src-pages-conseil-dentretien-index-js": () => import("./../../../src/pages/conseil d'entretien/index.js" /* webpackChunkName: "component---src-pages-conseil-dentretien-index-js" */),
  "component---src-pages-council-care-index-js": () => import("./../../../src/pages/council care/index.js" /* webpackChunkName: "component---src-pages-council-care-index-js" */),
  "component---src-pages-en-dev-404-page-js": () => import("./../../../src/pages/en/dev-404-page.js" /* webpackChunkName: "component---src-pages-en-dev-404-page-js" */),
  "component---src-pages-eshop-clothing-index-js": () => import("./../../../src/pages/eshop/clothing/index.js" /* webpackChunkName: "component---src-pages-eshop-clothing-index-js" */),
  "component---src-pages-eshop-etoffes-index-js": () => import("./../../../src/pages/eshop/etoffes/index.js" /* webpackChunkName: "component---src-pages-eshop-etoffes-index-js" */),
  "component---src-pages-eshop-fabrics-index-js": () => import("./../../../src/pages/eshop/fabrics/index.js" /* webpackChunkName: "component---src-pages-eshop-fabrics-index-js" */),
  "component---src-pages-eshop-home-index-js": () => import("./../../../src/pages/eshop/home/index.js" /* webpackChunkName: "component---src-pages-eshop-home-index-js" */),
  "component---src-pages-eshop-index-js": () => import("./../../../src/pages/eshop/index.js" /* webpackChunkName: "component---src-pages-eshop-index-js" */),
  "component---src-pages-eshop-maison-index-js": () => import("./../../../src/pages/eshop/maison/index.js" /* webpackChunkName: "component---src-pages-eshop-maison-index-js" */),
  "component---src-pages-eshop-sur-mesure-index-js": () => import("./../../../src/pages/eshop/sur mesure/index.js" /* webpackChunkName: "component---src-pages-eshop-sur-mesure-index-js" */),
  "component---src-pages-eshop-tailored-index-js": () => import("./../../../src/pages/eshop/tailored/index.js" /* webpackChunkName: "component---src-pages-eshop-tailored-index-js" */),
  "component---src-pages-eshop-vestiaire-index-js": () => import("./../../../src/pages/eshop/vestiaire/index.js" /* webpackChunkName: "component---src-pages-eshop-vestiaire-index-js" */),
  "component---src-pages-fr-dev-404-page-js": () => import("./../../../src/pages/fr/dev-404-page.js" /* webpackChunkName: "component---src-pages-fr-dev-404-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informations-cgv-js": () => import("./../../../src/pages/informations/cgv.js" /* webpackChunkName: "component---src-pages-informations-cgv-js" */),
  "component---src-pages-informations-faq-js": () => import("./../../../src/pages/informations/faq.js" /* webpackChunkName: "component---src-pages-informations-faq-js" */),
  "component---src-pages-informations-mentions-legales-js": () => import("./../../../src/pages/informations/mentions-legales.js" /* webpackChunkName: "component---src-pages-informations-mentions-legales-js" */),
  "component---src-pages-informations-sizeguide-js": () => import("./../../../src/pages/informations/sizeguide.js" /* webpackChunkName: "component---src-pages-informations-sizeguide-js" */),
  "component---src-pages-store-locator-index-js": () => import("./../../../src/pages/storeLocator/index.js" /* webpackChunkName: "component---src-pages-store-locator-index-js" */),
  "component---src-pages-univers-index-js": () => import("./../../../src/pages/univers/index.js" /* webpackChunkName: "component---src-pages-univers-index-js" */)
}

